<template>
  <div class="menu" ref="homePage">
    <!-- 曲線圖 -->
    <!-- <div class="mock-up">
      <div class="overlay">
        <img src="../img/louisa.png" >
      </div>
    </div> -->

    <div id="layer" v-if="loading"/>
    <!-- 沒有品項 -->
    <div class="box" v-show="shopCart.length === 0">
      <div class="dia">
        <div style="transform: scale(5); width: 50px; height: 20px;margin: 35px auto; margin-top: 30px;">
          <i class="cubeic-warn"></i><br><br>
        </div>
        <span style="color: #3F3F3F;">購物車目前無商品，請選擇門市進行消費。</span>
      </div>
    </div>

    <CreditCard @gotoP="gotoP" v-if="creditCard === true" :creditContent="creditContent">

    </CreditCard>

    <!-- 購物車品項 -->
    <div class="box" v-show="shopCart.length !== 0">
      <cube-loading :size="35" class="loading" v-if="loading"></cube-loading>
      <div class="item-box">
        <ul class="list">
          <div class="item" style="height: 30px;">
            <div class="left">
              <li class="title" style="margin-top: -20px;">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1.1);color:#2b2b2b; font-weight: 500;">品項訂單</span>
              </li>
            </div>
            <div class="right" style="color:#454545; margin-top: -20px; margin-left: -10px;" @click="emptyCart">
              <li class="title" style=" margin-left: 0px;"><i style="margin: 20px 7px; background-color: var(--main-color) ;border-radius: 50%; color: #ffffffff; transform: scale(1.1);" class="cubeic-delete"></i>清空</li>
            </div>
          </div>
          <div class="item" v-for="(item, index) in shopCart" :key="index" >
            <input type="checkbox" class="checkbox" :value="item" v-model="checkList">
            <div class="left" style="width: 80px; margin-left: 20px;">
              <img :src="item.img"  width="100%" height="55px" style="border-radius:3px; margin-left: -5px; margin-top: -20px;" >
            </div>
            <!-- 飲品 -->
            <div class="middle" style="width:180px; margin-left: 0px; margin-top: -5px">
              <li class="title">{{ item.title }}</li>
              <li class="entitle">{{ item.iceSelect.title }}/{{ item.sugarSelect.title }} / <span v-for="(item, index) in item.foodSelect" :key="index"> {{ item.title.length > 1 ? item.title.substr(0,3) : item.title }} </span></li>
              <li class="money">NT.{{ item.price * item.num }}</li>
            </div>
            <!-- 食物 -->
            <!-- <div class="middle" style="width:160px; margin-left: 0px; margin-top: -5px" v-if="item.type === '食物'">
              <li class="title">{{ item.title }}</li>
              <li class="entitle" style="height:15px;">{{ item.foodSelect.toString() }} {{ item.drinkSelect.toString() }} {{ item.iceSelect }}/{{ item.sugarSelect }}</li>
              <li class="money">NT.{{ item.f_price * item.num }}</li>
            </div> -->
            <div class="right" style="width:100px; transform: scale(1.2) translateY(15px); ">
              <i class="cubeic-remove" style="color: var(--main-color)" @click="minus(index)"/> <div style="color: black; display: inline-block; text-align:center; transform: scale(0.9) translateY(-1px); width:30px; height: 5px;line-height: 10px;">{{ item.num }}</div> <i style="color: var(--main-color)" class="cubeic-add" @click="plus(index)"/>
            </div>
          </div>
          <div class="item" style="height: 50px;">
            <div class="left">
              <!-- DEV NOTE:20210914 修改寬度讓希望取餐時間顯示 ping -->
              <li class="title" style="width: 120px; margin-left: 0px;">
                <!-- DEV NOTE:20210914 將取餐時間改為希望取餐時間 ping -->
                <div style="margin-top: 0px; margin-left: 15px; transform: scale(1);color:#2b2b2b; font-weight: 500;">希望取餐時間</div>
              </li>
            </div>
            <div class="middle" style="width:80px; margin-left: 0px; margin-top: 0px ;font-weight: 500;">{{ showTime }}
            </div>
            <div class="right" style="width:80px; transform: scale(1.2) translateY(0px); ">
              <i class="cubeic-time" style="color:transparent;"/> <div style="display: inline-block; text-align:center; transform: scale(1.2) translateY(-1px); width:30px; height: 5px;line-height: 10px;"> <i class="cubeic-time" style="color: var(--main-color)" @click="showTimePicker"/></div> <i  class="cubeic-add" style="color:transparent;"/>
            </div>
          </div>
          <div class="item" style="height: 50px;">
            <div class="left">
              <li class="title" style="margin-top: 0px;">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1.1);color:#2b2b2b; font-weight: 500;">統一編號</span>
              </li>
            </div>
            <div class="middle">
              <li class="title" style="width: 180px;"><cube-input v-if="SID === true" v-model="SIDForm" :maxlength="8" placeholder="請輸入.." style="border: none;" ></cube-input></li>
            </div>
            <div class="right" style="margin-top: 0px; margin-left: -10px;">
              <li class="title"  style=" margin-left: 10px;"><cube-switch v-model="SID"/></li>
            </div>
          </div>
          <div class="item" style="height: 50px;">
            <div class="left">
              <li class="title" style="margin-top: 0px;">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1.1);color:#2b2b2b; font-weight: 500;">備註</span>
              </li>
            </div>
            <div class="middle">
              <li class="title" style="width: 180px;"><cube-input v-if="openNote === true" v-model="note" :maxlength="30" placeholder="請輸入.." style="border: none;" ></cube-input></li>
            </div>
            <div class="right" style="margin-top: 0px; margin-left: -10px;">
              <li class="title"  style=" margin-left: 10px;"><cube-switch v-model="openNote"/></li>
            </div>
          </div>
          <div class="item" style="height: 50px;">
            <div class="left">
              <li class="title" style="margin-top: 0px;">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1.1);color:#2b2b2b; font-weight: 500;">取貨門市</span>
              </li>
            </div>
            <div class="middle">
              <li class="title" style="width: 175px;">{{ Shop.name }} </li>
            </div>
            <div class="right" style="color:#454545; margin-top: 0px; margin-left: -10px;">
              <li class="title"  style=" margin-left: 0px;"></li>
            </div>
          </div>
          <!-- <div class="item" style="height: 50px;">
            <div class="left">
              <li class="title" style="margin-top: 0px;">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1.1);color:#2b2b2b; font-weight: 500;">折扣前金額</span>
              </li>
            </div>
            <div class="middle" style="width:217px; margin-left: 0px; margin-top: -16px">
              <li class="money" style="color:black; font-weight: 500;" > NT.{{  withoutDiscount() }} </li>
            </div>
          </div> -->
          <!-- DEV NOTE: 20210916 ping 隱藏信用卡修改為option2 -->
          <div class="item" style="height: 75px;" v-if="hostName.indexOf('iluxurycard-dev') === -1 || hostName.indexOf('iluxurycardd2v') === -1">
            <div class="left" style="width:110px;">
              <li class="title" style="margin-top: 0px;transform: translateY(-35%)">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1);color:#2b2b2b; font-weight: 500;">付款方式</span>
              </li>
            </div>
            <div  class="middle" style="width:250px; transform: translateX(0%); margin-top: -2px;">
              <div v-for="(item, index) in option2" :key="index" style="width:150px; transform: translateY(-50%); ">
                <div >
                  <input style="margin: 3px; display: table-cell; vertical-align: middle; background-color: var(--main-color);" type="radio" :value="item" v-model="paymentMethod">
                    <span style="padding: 70px 0;">
                      <img v-if="item.payName === '街口支付'" src="../img/jko.png" width="40" height="20" style="margin-left: 5px; transform: translateY(25%); margin:0px;">
                      <img v-if="item.payName === 'LINE Pay'" src="../img/line-pay1.png" width="40" height="20" style="margin-left: 5px; transform: translateY(25%); margin:0px;"> {{ item.payName }}
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item" style="height: 75px;" v-else>
            <div class="left" style="width:110px;">
              <li class="title" style="margin-top: 0px;transform: translateY(-35%)">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1);color:#2b2b2b; font-weight: 500;">付款方式</span>
              </li>
            </div>
            <div  class="middle" style="width:250px; transform: translateX(0%); margin-top: -2px;">
              <div v-for="(item, index) in option" :key="index" style="width:150px; transform: translateY(-50%); ">
                <div >
                  <input style="margin: 3px; display: table-cell; vertical-align: middle; background-color: var(--main-color);" type="radio" :value="item" v-model="paymentMethod">
                    <span style="padding: 70px 0;">
                      <img v-if="item.payName === '街口支付'" src="../img/jko.png" width="40" height="20" style="margin-left: 5px; transform: translateY(25%); margin:0px;">
                      <img v-if="item.payName === 'LINE Pay'" src="../img/line-pay1.png" width="40" height="20" style="margin-left: 5px; transform: translateY(25%); margin:0px;"> {{ item.payName }}
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item" style="height: 50px; border:none; position: fixed; bottom: 40px; width: 100%; background-color: white;"  v-if="!fixed">
            <div class="left">
              <li class="title" style="margin-top: 0px;">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1.1);color:#2b2b2b; font-weight: 700;">總金額</span>
              </li>
            </div>
            <div class="middle" style="width:10px; margin-left: 0px; margin-top: 0px">
              <li class="money" style="color:black; font-weight: 500;" > NT.{{  totalPrice() }} </li>
            </div>
            <div class="right" style="color:#454545; margin-top: 0px; margin-left: 55px;" @click="firstGate">
              <li class="title"  style=" margin-left: 0px;"><cube-button class="bottom_right_button" style="background-color: var(--main-color)"  :primary="true">送出訂單</cube-button></li>
            </div>
          </div>
          <div class="item" style="height: 50px; border:none; margin-bottom: 150px;"  v-else>
            <div class="left">
              <li class="title" style="margin-top: 0px;">
                <span style="margin-top: 0px; margin-left: 15px; transform: scale(1.1);color:#2b2b2b; font-weight: 500;">總金額</span>
              </li>
            </div>
            <div class="middle" style="width:10px; margin-left: 0px; margin-top: 0px">
              <li class="money" style="color:black; font-weight: 500;" > NT.{{  totalPrice() }} </li>
            </div>
            <div class="right" style="color:#454545; margin-top: 0px; margin-left: 55px; transform: translateX(1%);" @click="firstGate">
              <li class="title"  style=" margin-left: 0px;"><cube-button class="bottom_right_button" style="background-color: var(--main-color)" :primary="true">送出訂單</cube-button></li>
            </div>
          </div>
        </ul>
      </div>
      <!-- <div class="box2">
        <div class="item-box2" style="height: 75px; width: 100%; top: 25px; border-radius: 0px; overflow-y: none;">
          <ul class="list">
            <div class="item" style="border:none;">
              <div class="left">
                <li class="title" style="margin-top: 0px;">
                  <span style="margin-top: 0px; margin-left: 0px; transform: scale(1.1);color:#2b2b2b; font-weight: 500;">總金額</span>
                </li>
              </div>
              <div class="middle" style="width:40px; margin-left: 0px; margin-top: -0px">
                <li class="money" style="color:black; font-weight: 500;" > NT.{{  totalPrice() }} </li>
              </div>
              <div class="right" style="color:#454545; margin-top: 0px; margin-left: 0px;" @click="beforeSendOrder">
                <li class="title"  style=" margin-left: 0px;"><cube-button class="bottom_right_button"  :primary="true">送出訂單</cube-button></li>
              </div>
            </div>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import CreditCard from '@/components/user/creditCard.vue'
// eilis
// const APP_ID = '13409'
// const APP_KEY = 'app_okF2foaTRN9l2LxPWs03FxMbHJB8CF8oNDhrF5JXTHefqyegooEKRAaufdwv'

const APP_ID = '121466'
const APP_KEY = 'app_av0qlQMRfSby5hjbbcSuXYO5NAMGIB9QsuFCvLqhQfBpp9R39kEVsjGGenPE'
const hostName = window.location.host
// hostname.indexOf('iluxurycard-dev') !== -1
// console.log( " 網頁可見區域高："+ document.body.clientHeight)
// console.log( " 螢幕解析度的高："+ window.screen.height)
// console.log( " 螢幕可用工作區高度："+ window.screen.availHeight)
export default {
  data () {
    return {
      hostName: hostName,
      option: [
        { payName: '實體付款',payWay: 'cash'},
        { payName: '信用卡',payWay: 'creditCard'},
        { payName: 'LINE Pay',payWay: 'linePay'},
        { payName: '街口支付',payWay: 'jkoPay'}],
      option2: [
        { payName: '實體付款',payWay: 'cash'},
        // { payName: '信用卡',payWay: 'creditCard'},
        { payName: 'LINE Pay',payWay: 'linePay'},
        { payName: '街口支付',payWay: 'jkoPay'}],
      paymentMethod: { payName: '實體付款',payWay: 'cash'},
      creditCard: false,
      creditContent: {},
      fixed: false,
      showTime: '',
      selectedTime: '',
      openNote: false,
      note: '',
      loading: false,
      SID: false,
      SIDForm: '',
      shop: localStorage.getItem('locatedShop'),
      shopNo: localStorage.getItem('locatedShopNum'),
      payment: false,
      availHeight: document.documentElement.offsetHeight,
      redeemP: 0,
      tempPrice: [],
      checkList: [],
      finalList: [],
      index: '',
    }
  },
  components: {
    CreditCard
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  props: ['eid','userName','phone', 'points'],
  mounted() {
    this.availHeight = `${window.screen.availHeight}`;
    this.$refs.homePage.style.height = this.availHeight+ 'px';
    console.log(this.$refs.homePage.style.height);
    this.checkList = this.shopCart
    TPDirect.setupSDK(APP_ID, APP_KEY, 'production'); // LINEPAY用
    // DEV NOTE: 20210914 Mark change 'sandbox' to 'production'
  },
  watch: {
    SIDForm(val) {
      console.log(val);
      var reg = /^[0-9]+$/
      if (!reg.test(val)) {
        alert('請輸入數字');
        // this.SIDForm = '';
      }
       else if (val.match(/^\d{8}$/)) {
        this.checkTB(val);
      }
    },
    SID (val) {
      // console.log(val);
      if (val === false) {
        this.SIDForm = ''
      }
    },
    checkList(val) {
      // console.log(val.filter(el => el.AreaType !== 'C' || el.AreaType !== 'B').length);
      const a = val.filter(el => el.AreaType !== 'C' || el.AreaType !== 'B').length
      if (a !== !a) {
        this.redeemP = 0;
      }
    }
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      personalData: 'personalData',
      brandData: 'brandData',
      shopCart: 'shopCart',
      imCart: 'imCart',
      Shop: 'Shop',
    }),
    shopCar: {
      get () { return this.shopCart },
      set (value) { this.updateShopCart({ shopCart: value }); },
    },
    shopContent: {
      get () { return this.Shop },
      set (value) { this.updateShopName({ ShopName: value }); },
    },
    total() {
      // onsole.log(this.checkList);
      const standardPrice = this.checkList.filter(el => el.Areatype !== 'C')
      let totalPrice = 0;
      let totalFoodPrice = 0;
      for (let i = 0; i < this.checkList.length; i++) {
        if (this.checkList[i].type === '飲品') {
          totalPrice += parseInt(this.checkList[i].item_price) * this.checkList[i].num;
        } else {
          totalFoodPrice += parseInt(this.checkList[i].f_price) * this.checkList[i].num
        }
      }
      const combine = totalPrice + totalFoodPrice;
      return combine;
    },
    totalF() {
      // console.log(this.checkList);
      let totalFoodPrice = 0;
      for (let i = 0; i < this.checkList.length; i++) {
        totalFoodPrice += parseInt(this.checkList[i].f_price) * this.checkList[i].num
      }
      return totalFoodPrice;
    },
  },
  methods: {
  ...mapActions({
    azureSendOnlineOrder: 'azureSendOnlineOrder',
    getAzureToken: 'getAzureToken',
  }),
  ...mapMutations({
    updateShopCart: 'updateShopCart'
  }),
    gotoP () {
      this.$emit('gotochart', '首頁', '購物車')
    },
    firstGate() {
      if (!this.selectedTime) {
        const toast = this.$createToast({
          type: 'correct',
          txt: '請選擇取貨時間',
          time: 1000
        }).show()
        return toast.show();
      }
      // DEV NOTE:20210914 判斷單品項大於8 ping
      let itemNum = this.shopCart.filter(e => e.num > 8)
      // DEV NOTE:20210915 陣列存放各品項數量、並加總判斷有無大於8 ping
      let singleItemNum = this.shopCar.map(e => e.num)
      let allItemNume = singleItemNum.reduce((a,b)=>a+b)
      // DEV NOTE:20210915 若無單品項大於8杯或加總小於8杯 ping
      if (itemNum.length == '0' && allItemNume < 8) {
        this.$createDialog({
        type: 'alert',
        // DEV NOTE:20210914 修改標題內容 ping
        title: '訂單送出通知',
        content: '點選確定即送出訂單，請確認訂單內容再下單。​實際完成時間請依照訊息通知為主',
        showClose: true,
        // icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
            this.sendAuzreOrder()
        },
        }).show()
      } 
      else {
       this.$createDialog({
        type: 'alert',
        title: '訂單送出通知',
        content: '訂購數量較多，建議購買提袋，店員將與您聯繫訂單內容。',
        showClose: true,
        // icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
            this.sendAuzreOrder()
          },
        }).show()
      }
    },
    sendAuzreOrder() {
      // console.log(this.SIDForm);
      // return;
      // console.log(this.eilisProfile);
      // console.log(this.personalData);
      // console.log(this.brandData);
      console.log(this.checkList);
      // console.log(this.Shop);
      // console.log(this.totalPrice());
      // console.log(this.paymentMethod);
      this.$loading.show();
      // this.$loading.hide();
      // let list = []
      let listDetail = []
      console.log(this.checkList);
      for (let i = 0 ; i < this.checkList.length; i ++) {
        listDetail[i] = {
          itemId: this.checkList[i].itemId,
          inputQty: this.checkList[i].num,
          itemModifierList:[]
        }
        for (let e = 0; e < this.checkList[i].foodSelect.length; e ++) {
          const foodSelect = this.checkList[i].foodSelect[e];
          listDetail[i].itemModifierList.push({
            modifierGroupHeaderId: foodSelect.modifierGroupHeaderId,
            itemId: foodSelect.itemId
          })
        }
        // 糖
        if(this.checkList[i].sugarSelect.itemId){
          listDetail[i].itemModifierList.push({
            modifierGroupHeaderId: this.checkList[i].sugarSelect.modifierGroupHeaderId,
            itemId: this.checkList[i].sugarSelect.itemId
          });
        }
        // 冰
        if (this.checkList[i].iceSelect.itemId) {
          listDetail[i].itemModifierList.push({
            modifierGroupHeaderId: this.checkList[i].iceSelect.modifierGroupHeaderId,
            itemId: this.checkList[i].iceSelect.itemId
          });
        }
      }
      console.log(listDetail);
      // this.$loading.hide();
      // this.$emit('gotochart', '首頁')
      // this.shopCar = [];
      // return;
      // for (let i = 0 ; i < this.checkList.length; i ++) {
      //   // for (let e = 0 ; e < this.checkList[i].foodSelect.length; e ++ ) {
      //     listDetail[i] = {
      //       itemId: this.checkList[i].itemId,
      //       inputQty: this.checkList[i].num,
      //       itemModifierList:[
      //         // {
      //         //   modifierGroupHeaderId: this.checkList[i].foodSelect.length > 0 ? this.checkList[i].foodSelect[e].modifierGroupHeaderId : '',
      //         //   itemId: this.checkList[i].foodSelect.length > 0 ? this.checkList[i].foodSelect[e].itemId : '',
      //         // }
      //       ]
      //     }
      //   // }
      //   // console.log(listDetail);
      //   let sugar = {}
      //   let ice = {}
      //   sugar = {
      //     modifierGroupHeaderId: this.checkList[i].sugarSelect.modifierGroupHeaderId,
      //     itemId: this.checkList[i].sugarSelect.itemId,
      //   }
      //   ice = {
      //     modifierGroupHeaderId: this.checkList[i].iceSelect.modifierGroupHeaderId,
      //     itemId: this.checkList[i].iceSelect.itemId,
      //   }
      //   console.log(ice);
      //   console.log(sugar);
      //   console.log(listDetail);
      //   listDetail[i].itemModifierList = [];
      //   // return;
      //   // for (let e = 0 ; e < this.checkList[i].foodSelect.length; e ++ ) {
      //   //   if (this.checkList[i].foodSelect.length > 0) {
      //   //     listDetail[i] = {
      //   //       itemId: this.checkList[i].itemId,
      //   //       inputQty: this.checkList[i].num,
      //   //       itemModifierList:[{
      //   //           modifierGroupHeaderId: this.checkList[i].foodSelect[e].modifierGroupHeaderId,
      //   //           itemId: this.checkList[i].foodSelect[e].itemId,
      //   //         }
      //   //       ]
      //   //     }
      //   //     listDetail[i].itemModifierList.push(sugar,ice)
      //   //   } else {
      //   //     listDetail[i] = {
      //   //       itemId: this.checkList[i].itemId,
      //   //       inputQty: this.checkList[i].num,
      //   //       itemModifierList:[{
      //   //           modifierGroupHeaderId: '',
      //   //           itemId: '',
      //   //         }
      //   //       ]
      //   //     }
      //   //     listDetail[i].itemModifierList.push(sugar,ice)
      //   //   }
      //   // }
      //   listDetail[i].itemModifierList.push(sugar,ice)
      // }
      console.log(this.paymentMethod.payWay);
      // return;
      // 實體現金付款
      if (this.paymentMethod.payWay === 'cash') {
        this.azureSendOnlineOrder({
          accountId: this.eilisProfile.accountId,
          orderId: this.Shop.orderToken,
          posId: this.Shop.posId,
          userId: this.personalData.userId,
          name: this.personalData.name,
          phone: this.personalData.phone,
          payWay: this.paymentMethod.payWay,
          price: this.totalPrice(),
          totalPrice: this.totalPrice(),
          itemList: listDetail,
          bookTime: this.selectedTime,
          ban: this.SIDForm, // 統編
          remark: this.note, // 備註
        })
        .then((res) => {
          console.log(res);
          if (res.data.resultCode === 'OK') {
            const toast = this.$createToast({
              type: 'correct',
              txt: '訂單已送出',
              time: 1000
            })
            this.getAzureToken({
              poiId: this.Shop.poiId,
              secretkey: this.Shop.secretkey,
            })
            .then((res) => {
              console.log(res);
              if (res.data && res.data.shopId) {
                toast.show();
                this.$emit('gotochart', '首頁', '購物車')
                this.shopCar = [];
                this.shopContent.orderToken = res.data.orderToken;
                this.$loading.hide();
              } else {
                this.$loading.hide();
              }
            })
          } else {
            this.$loading.hide();
            const toast = this.$createToast({
              type: 'correct',
              txt: '請重新操作',
              time: 1000
            })
            this.getAzureToken({
              poiId: this.Shop.poiId,
              secretkey: this.Shop.secretkey,
            })
            .then((res) => {
              // console.log('');
              if (res.data && res.data.shopId) {
                toast.show();
                this.shopContent.orderToken = res.data.orderToken;
                this.$loading.hide();
              } else {
                this.$loading.hide();
              }
            })
          }
        })
        .catch(() => {
          this.$loading.hide();
          const toast = this.$createToast({
            type: 'correct',
            txt: '請重新操作',
            time: 1000
          }).show()
          toast.show();
        })
      } else if (this.paymentMethod.payWay === 'linePay') {
        TPDirect.linePay.getPrime(result => {
          console.log(result);
          if (result.status !== 0) {
            // get prime error
            console.log(result.msg);
            this.$createToast({
            type: 'error',
            txt: '請重新操作',
            time: 1500
          }).show()
          this.$loading.hide()
          return;
          }
          let primeContent = result.prime;
          this.azureSendOnlineOrder({
            accountId: this.eilisProfile.accountId,
            orderId: this.Shop.orderToken,
            posId: this.Shop.posId,
            userId: this.personalData.userId,
            name: this.personalData.name,
            phone: this.personalData.phone,
            payWay: this.paymentMethod.payWay,
            price: this.totalPrice(),
            totalPrice: this.totalPrice(),
            itemList: listDetail,
            bookTime: this.selectedTime,
            ban: parseInt(this.SIDForm), // 統編
            remark: this.note, // 備註
            callbackUrl: `https://liff.line.me/${this.eilisProfile.liffId}`,
            prime: primeContent,
            bankId: 'line',
            cardholder: {
              phone_number: '',
              name: '',
              email: '',
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.status === 10020) {
              location.href = res.data.paymentUrl
              this.$loading.hide();
            }
          })
        })
      } else if (this.paymentMethod.payWay === 'jkoPay') {
        TPDirect.jkoPay.getPrime(result => {
          console.log(result);
          if (result.status !== 0) {
            // get prime error
            console.log(result.msg);
            this.$createToast({
            type: 'error',
            txt: '請重新操作',
            time: 1500
          }).show()
          this.$loading.hide()
          return;
          }
          let primeContent = result.prime;
          this.azureSendOnlineOrder({
            accountId: this.eilisProfile.accountId,
            orderId: this.Shop.orderToken,
            posId: this.Shop.posId,
            userId: this.personalData.userId,
            name: this.personalData.name,
            phone: this.personalData.phone,
            payWay: this.paymentMethod.payWay,
            price: this.totalPrice(),
            totalPrice: this.totalPrice(),
            itemList: listDetail,
            bookTime: this.selectedTime,
            ban: parseInt(this.SIDForm), // 統編
            remark: this.note, // 備註
            callbackUrl: `https://liff.line.me/${this.eilisProfile.liffId}`,
            prime: primeContent,
            bankId: 'jko',
            cardholder: {
              phone_number: '',
              name: '',
              email: '',
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.status === 10020) {
              location.href = res.data.paymentUrl
              this.$loading.hide();
            }
          })
        })
      } else {
        this.creditContent = {
          accountId: this.eilisProfile.accountId,
          orderId: this.Shop.orderToken,
          posId: this.Shop.posId,
          userId: this.personalData.userId,
          name: this.personalData.name,
          phone: this.personalData.phone,
          payWay: this.paymentMethod.payWay,
          price: this.totalPrice(),
          totalPrice: this.totalPrice(),
          itemList: listDetail,
          bookTime: this.selectedTime,
          ban: parseInt(this.SIDForm), // 統編
          remark: this.note, // 備註
        },
        this.creditCard = true;
        // this.onSubmit()
        this.$loading.hide();
      }
    },
    handleScroll() {
      // height 的 650 是預估所有欄位高度
      console.log((75 * this.checkList.length) + 350);
      console.log(document.documentElement.clientHeight);
      const height = ((75 * this.checkList.length) + 350) - document.documentElement.clientHeight
      // console.log(((75 * this.checkList.length) + 350));
      // console.log('DOM高度減去可視區域高:' + height);
      var scrollH = document.body.scrollTop || document.documentElement.scrollTop;
      // console.log('捲動高:' + scrollH);
      if (scrollH >= height) {
        console.log('捲動高:' + scrollH);
        console.log('DOM高度減去可視區域高:' + height);
        this.fixed = true;
        console.log(true);

      }
      else {
        this.fixed = false;
        console.log(false);

      }
    },
    checkTB(val){
      var tbNum = new Array(1,2,1,2,1,2,4,1);
      var temp = 0;
      var total = 0;
        for(var i = 0; i < tbNum.length ;i ++){
            temp = val.charAt(i) * tbNum[i];
            total += Math.floor(temp/10)+temp%10;
        }
        if (total%10==0 || (total%10==9 && val.charAt(6)==7)) {
          // alert('營利事業統一編號正確!');
        } else {
          alert('營利事業統一編號錯誤!');
          this.SIDForm = '';
          }
    },
    showTimePicker () {
      console.log(this.Shop.openTime);
      let openTime = this.Shop.openTime.substr(6,5).replace(':','')
      console.log(openTime);
      let openTimeHr = parseInt(openTime.substr(0,2))
      let openTimeMt = parseInt(openTime.substr(2,4)) === 0 ? parseInt(openTime.substr(2,4))+ `${0}` : parseInt(openTime.substr(2,4))
      let closeTime = this.Shop.openTime.substr(12,5).replace(':','')
      let closeTimeHr = parseInt(closeTime.substr(0,2))
      console.log(closeTime);
      // console.log(closeTime.substr(2,4).length);
      let closeTimeMt = parseInt(closeTime.substr(2,4)) === 0 ?  parseInt(closeTime.substr(2,4))+ `${0}` : parseInt(closeTime.substr(2,4))
      console.log(closeTimeMt);
      const arr = []
      // const a = new Date(Date.now()+1000*60*15).getHours().toString();
      // DEV NOTE:20210922 ping 間隔30分鐘
      const a = new Date(Date.now()+1000*60*30).getHours().toString();
      console.log(a);
      // const b = new Date(Date.now()+1000*60*15).getMinutes().toString();
      // DEV NOTE:20210922 ping 間隔30分鐘
      const b = new Date(Date.now()+1000*60*30).getMinutes().toString();
      console.log(b);
      arr.push(a,b)
      console.log(arr);
      // DEV NOTE:20210922 ping 8點至營業時間可訂購，取餐時間為開店後30分鐘 === VVV
      // DEV NOTE:20210922 ping 如果開店時間為11:30，變成12，否則開店時間11:00則+30分鐘
      let openTimeGetFood = []
      if (openTimeMt == 30) {
        openTimeGetFood.push(openTimeHr + 1, 0)
      }
      else {
        openTimeGetFood.push(openTimeHr, openTimeMt + 30)
      }
      let timeNowHours = new Date().getHours().toString() < 10 ? `0${new Date().getHours().toString()}` : new Date().getHours().toString();
      let timeNowMinute = new Date().getMinutes().toString() < 10 ? `0${new Date().getMinutes().toString()}` : new Date().getMinutes().toString();
      const timeNow = timeNowHours.toString() + timeNowMinute.toString()
      const timeClose = closeTimeHr.toString() + closeTimeMt.toString()
      console.log(timeClose);
      console.log(timeNow);
      if (parseInt(a) >= "08" && timeNow < openTime) {
          this.$createDatePicker({
          min: openTimeGetFood,
          max: [closeTimeHr,closeTimeMt],
          cancelTxt: '取消',
          confirmTxt: '確定',
          subtitle: '',
          value: openTimeGetFood,
          columnCount: 2,
          title: '選擇時間',
          startColumn: 'hour',
          showNow: false,
          delay: 30,
          day: {
            len: 1,
            filter: ['今天'],
            // format: 'M月d日'
          },
          onSelect: (selectedTime, selectedText, formatedTime) => {
            // console.log(selectedTime);
            const Year = new Date().getFullYear().toString();
            const Month = (new Date().getMonth()+1).toString() < 10 ? `${(new Date().getMonth()+1).toString()}` : (new Date().getMonth()+1).toString();
            const MMonth = parseInt(Month) - 1
            const Day = new Date().getDate().toString() < 10 ? `0${new Date().getDate().toString()}` : new Date().getDate().toString();
            const dhours = new Date(selectedTime).getHours().toString() < 10 ? `0${new Date(selectedTime).getHours().toString()}` : new Date(selectedTime).getHours().toString();
            const dmts = new Date(selectedTime).getMinutes().toString() < 10 ? `0${new Date(selectedTime).getMinutes().toString()}` : new Date(selectedTime).getMinutes().toString();
            const dseconds = new Date(selectedTime).getSeconds().toString() < 10 ? `0${new Date(selectedTime).getSeconds().toString()}` : new Date(selectedTime).getSeconds().toString()
            console.log(parseInt(Month) - 1);
            // this.selectedTime = dhours+dmts+dseconds;
            this.selectedTime = new Date(Year,MMonth,Day,dhours,dmts,dseconds).toISOString();
            console.log(this.selectedTime);
            this.showTime = dhours + ':' + dmts
          },
        }).show()
      }
      else if (parseInt(a) < openTimeHr || timeNow > timeClose) {
        return alert('非營業時間')
      }
      else {
        this.$createDatePicker({
          min: arr,
          max: [closeTimeHr,closeTimeMt],
          cancelTxt: '取消',
          confirmTxt: '確定',
          subtitle: '',
          value: arr,
          columnCount: 2,
          title: '選擇時間',
          startColumn: 'hour',
          showNow: false,
          delay: 30,
          day: {
            len: 1,
            filter: ['今天'],
            // format: 'M月d日'
          },
          onSelect: (selectedTime, selectedText, formatedTime) => {
            // console.log(selectedTime);
            const Year = new Date().getFullYear().toString();
            const Month = (new Date().getMonth()+1).toString() < 10 ? `${(new Date().getMonth()+1).toString()}` : (new Date().getMonth()+1).toString();
            const MMonth = parseInt(Month) - 1
            const Day = new Date().getDate().toString() < 10 ? `0${new Date().getDate().toString()}` : new Date().getDate().toString();
            const dhours = new Date(selectedTime).getHours().toString() < 10 ? `0${new Date(selectedTime).getHours().toString()}` : new Date(selectedTime).getHours().toString();
            const dmts = new Date(selectedTime).getMinutes().toString() < 10 ? `0${new Date(selectedTime).getMinutes().toString()}` : new Date(selectedTime).getMinutes().toString();
            const dseconds = new Date(selectedTime).getSeconds().toString() < 10 ? `0${new Date(selectedTime).getSeconds().toString()}` : new Date(selectedTime).getSeconds().toString()
            console.log(parseInt(Month) - 1);
            // this.selectedTime = dhours+dmts+dseconds;
            this.selectedTime = new Date(Year,MMonth,Day,dhours,dmts,dseconds).toISOString();
            console.log(this.selectedTime);
            this.showTime = dhours + ':' + dmts
          },
        }).show()
      }
      // DEV NOTE:20210922 ping 8點至營業時間可訂購，取餐時間為開店後30分鐘 === ^^^
      // DEV NOTE:20210922 ping 關掉原本的 === VVV
      // if (parseInt(a) < openTimeHr) {
      //   return alert('非營業時間')
      // }
      // this.$createDatePicker({
      //   min: arr,
      //   max: [closeTimeHr,closeTimeMt],
      //   cancelTxt: '取消',
      //   confirmTxt: '確定',
      //   subtitle: '',
      //   value: new Date(),
      //   columnCount: 2,
      //   title: '選擇時間',
      //   startColumn: 'hour',
      //   showNow: false,
      //   delay: 30,
      //   day: {
      //     len: 1,
      //     filter: ['今天'],
      //     // format: 'M月d日'
      //   },
      //   onSelect: (selectedTime, selectedText, formatedTime) => {
      //     // console.log(selectedTime);
      //     const Year = new Date().getFullYear().toString();
      //     const Month = (new Date().getMonth()+1).toString() < 10 ? `${(new Date().getMonth()+1).toString()}` : (new Date().getMonth()+1).toString();
      //     const MMonth = parseInt(Month) - 1
      //     const Day = new Date().getDate().toString() < 10 ? `0${new Date().getDate().toString()}` : new Date().getDate().toString();
      //     const dhours = new Date(selectedTime).getHours().toString() < 10 ? `0${new Date(selectedTime).getHours().toString()}` : new Date(selectedTime).getHours().toString();
      //     const dmts = new Date(selectedTime).getMinutes().toString() < 10 ? `0${new Date(selectedTime).getMinutes().toString()}` : new Date(selectedTime).getMinutes().toString();
      //     const dseconds = new Date(selectedTime).getSeconds().toString() < 10 ? `0${new Date(selectedTime).getSeconds().toString()}` : new Date(selectedTime).getSeconds().toString()
      //     console.log(parseInt(Month) - 1);
      //     // this.selectedTime = dhours+dmts+dseconds;
      //     this.selectedTime = new Date(Year,MMonth,Day,dhours,dmts,dseconds).toISOString();
      //     console.log(this.selectedTime);
      //     this.showTime = dhours + ':' + dmts
      //   },
      // }).show()
    // DEV NOTE:20210922 ping 關掉原本的 === ^^^
    },
    list() {
      // console.log(this.checkList);
      let emptyList = [];
      for (let i = 0; i < this.checkList.length; i += 1) {
        for (let e = 0; e < this.checkList[i].num; e += 1) {
          // emptyList.push(parseInt(this.checkList[i].item_price))
          emptyList.push(parseInt(this.checkList[i].price))
        }
      }
      return emptyList;
    },
    listC() {
      // console.log(this.checkList);
      let emptyList = [];
      for (let i = 0; i < this.checkList.length; i += 1) {
        for (let e = 0; e < this.checkList[i].num; e += 1) {
          // emptyList.push(parseInt(this.checkList[i].item_price))
          if (this.checkList[i].AreaType === 'C' || this.checkList[i].AreaType === 'B' || this.checkList[i].AreaType === 'D') {
            emptyList.push(parseInt(this.checkList[i].item_price) ? parseInt(this.checkList[i].item_price) : parseInt(this.checkList[i].f_price))
          }
        }
      }
      return emptyList;
    },
    withoutDiscount() {
      const array = this.list().sort((a, b) => b - a);
      const arrayC = this.listC().sort((a, b) => b - a);
      const discountCup = this.redeemP / 3;
      let origin = 0;
      let arrayCorigin = 0;
      for (let i = 0; i < array.length; i += 1) {
          origin += array[i];
      }
      for (let e = 0; e < arrayC.length; e += 1) {
        arrayCorigin += arrayC[e]
      }
      return origin + arrayCorigin;
    },
    totalPrice() {
      // let Pricelist = this.shopCart.map(el => el.price)
      // console.log(this.shopCart);
      // console.log('');

      // let final = ''
      // for (let i = 0; i < Pricelist.length; i ++) {
      //   return final += Pricelist[i]
      // }
      const array = this.list().sort((a, b) => b - a);
      const arrayC = this.listC().sort((a, b) => b - a);
      const discountCup = this.redeemP / 3;
      let origin = 0;
      let arrayCorigin = 0;
      for (let i = 0; i < array.length; i += 1) {
        if (i <= discountCup - 1) {
          origin += Math.round(array[i] * 0.9);
        }
        else {
          origin += array[i];
          // console.log(origin);
        }
      }
      for (let e = 0; e < arrayC.length; e += 1) {
        arrayCorigin += arrayC[e]
      }
      return origin + arrayCorigin;
    },
    PlusCalPoints() {
      const array = this.list().sort((a, b) => b - a);
      // console.log(array);
      if ((this.redeemP + 3) <= this.points) {
        const typeC = this.listC().sort((a, b) => b - a);
        // console.log(typeC);
        if (this.redeemP + 3 <= array.length * 3) {
          this.redeemP += 3;
        }
        else if (this.redeemP + 3 <= this.shopCart.length * 3) {
          // const typeC = this.listC().sort((a, b) => b - a);
          // console.log(typeC);
          if (this.checkList.length > 0 && typeC.length < 1) {
            if (this.redeemP + 3 > this.checkList.length * 3) {
              this.$createDialog({
                type: 'confirm',
                title: '系統通知',
                content: '請點選所需品項',
                icon: 'cubeic-alert',
                confirmBtn: {
                  text: '確定',
                  active: true,
                  disabled: false,
                  href: 'javascript:;'
                },
              }).show()
            } else {
              this.redeemP += 3;
            }
          } else if (this.checkList.length > 0 && typeC.length > 0) {
            if (this.redeemP + 3 > (this.checkList.length * 3 - typeC.length * 3)) {
              this.$createDialog({
                type: 'confirm',
                title: '系統通知',
                content: '餐點跟套餐及黑卡特價無法折抵',
                icon: 'cubeic-alert',
                confirmBtn: {
                  text: '確定',
                  active: true,
                  disabled: false,
                  href: 'javascript:;'
                },
              }).show()
            } else {
              this.redeemP += 3;
            }
          }
          else if (this.redeemP + 3 > this.checkList.length * 3) {
            this.$createDialog({
              type: 'confirm',
              title: '系統通知',
              content: '請點選所需品項',
              icon: 'cubeic-alert',
              confirmBtn: {
                text: '確定',
                active: true,
                disabled: false,
                href: 'javascript:;'
              },
            }).show()
          } else {
            this.redeemP += 3;
          }
        }
      }
    },
    MinusCalPoints() {
      if (this.redeemP !== 0) {
        this.redeemP -= 3;
      }
    },
    final() {
      let arr = [];
      for (let i = 0; i < this.checkList.length; i++) {
        if (this.shopCart.indexOf(this.checkList[i]) !== -1) {
          arr.push(this.checkList[i])
        }
      }
      this.finalList = arr;
      // console.log(this.finalList);
      // for (let i = 0; i < this.checkList.length; i++) {
      //   this.shopCart.filter(el => el.num * el.item_price === this.checkList[i].num * this.checkList[i].item_price)
      // }
    },
    beforeSendOrder() {
      if (this.checkList.length < 1) {
        this.$createDialog({
          type: 'confirm',
          title: '系統通知',
          content: '還未點選所需品項',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '取消',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
        }).show()
      } else if (this.selectedTime === '') {
        this.$createToast({
          type: 'correct',
          txt: '取貨時間必填',
          time: 1000
        }).show()
      }
      else {
        this.$createDialog({
          type: 'confirm',
          title: '系統通知',
          content: '確認訂單送出嗎？',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '取消',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
          onConfirm: () => {
            this.sendOrder()
          },
        }).show()
      }
    },
    // 送出訂單
    openPayment() {
      if (this.checkList.length < 1) {
        const dia = this.$createDialog({
          type: 'confirm',
          title: '系統通知',
          content: '還未加入品項',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '取消',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
        }).show()
        return dia;
      }
      this.payment = true;
    },
    closePayment() {
      this.payment = false;
    },
    beforeCheck(index) {
      // console.log(index);
      this.index = index;
      // this.checkList
      // console.log(this.checkList);
    },
    emptyCart() {
      this.$createDialog({
        type: 'confirm',
        title: '系統通知',
        content: '確認要清空購物車嗎？',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          this.shopCar = [];
        },
        // onCancel: () => {
        //   this.$createToast({
        //     type: 'warn',
        //     time: 1000,
        //     txt: '点击取消按钮'
        //   }).show()
        // }
      }).show()
    },
    plus(idx) {
      // console.log(idx);
      this.index = idx;
      if (this.shopCart[this.index].num > 39) {
        return this.shopCart[this.index].num;
      } else {
        this.shopCart[this.index].num += 1;
        // this.tempArr.push(this.shopCart[this.index].item_price ? parseInt(this.shopCart[this.index].item_price) * 1 : parseInt(this.shopCart[this.index].item_price) * 1 )
        // console.log(this.tempArr);
      }
    },
    minus(idx) {
      // console.log(idx);
      this.index = idx;
      this.redeemP = 0;
      // console.log(this.shopCart);
      // console.log("1064")
      if (this.shopCart[this.index].num < 2) {
        this.$createDialog({
          type: 'confirm',
          title: '系統通知',
          content: '確認要清空此品項嗎？',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '取消',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
          onConfirm: () => {
            this.shopCart.splice(this.index, 1);
          },
        }).show()
      } else {
        // this.tempArr.splice(this.shopCart[this.index], 1);
        // console.log(this.tempArr);
        this.shopCart[this.index].num -= 1;
      }
      // console.log(this.shopCart)
    },
  }
}
</script>

<style lang="scss" scoped>
  body {
  font: 300 14px/18px Roboto;
  // overflow-x:hidden;
  // overflow-y:hidden;
  // overflow: hidden;
  // height:100%;
}

*,
:after,
:before {
  box-sizing: border-box
}
  #layer{
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.419);
  }
  .loading {
    z-index: 100000;
    display: flex; // 置中
    align-items: center; // 置中
    justify-content: center; // 置中
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    // margin-top: -270px;
    width: 55px;
    height: 55px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #717171;
    -webkit-text-fill-color: transparent;
  }
  .dia0 {
    z-index: 100;
    padding: 30px;
    width: 99vw;
    background-color: white;
    max-width: 500px;
    height: 180px;
    border-radius: 10px;
    border: 1px solid var(--main-color);
    margin: 100px auto;
  }
  .dia {
    z-index: 100;
    padding: 30px;
    width:100%;
    background-color: white;
    max-width: 300px;
    height: 180px;
    border-radius: 10px;
    border: 1px solid var(--main-color);
    margin: 100px auto;
  }
  .dia .cubeic-warn {
    transform: scale(1.4);
    border-radius: 50%;
    background-clip: text;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color:var(--main-color);
    padding: 15px;
  }
  .menu {
    z-index: 1;
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    width: 100%;
    // height: 500px;
    max-width: 540px;
    background-color: #f9f9f9;
    // overflow: hidden;
    // -webkit-overflow-scrolling: touch;
    // overflow-y: auto;
    // -ms-overflow-y: auto;
    // overflow: scroll;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  // .mock-up {
  //   min-width: 310px;
  //   height: 500px;
  //   margin: 0em auto;
  //   position: relative;
  //   overflow: hidden;
  // }
  // .overlay {
  //   // background: url('../img/louisa.png') ;
  //   // background-color: #F57E28;
  //   // background: url(https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/header.jpg) no-repeat top /contain;
  //   position: absolute;
  //   left: -310px;
  //   transform: rotate(250deg);
  //   top: 0px;
  //   right: 0px;
  //   bottom: 120px;
  //   z-index: 1;
  // }
  .overlay:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background: rgba(71, 32, 84, 0.5);
  }
  .box {
    z-index: 10;
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 320px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    -ms-overflow-y: auto;
  }
  .item-box{
    background-color: #ffffff;
    padding-left: 0!important;
    padding-right: 0!important;
    // position: fixed;
    border-radius: 8px;
    margin: 0px auto;
    width: 99vw;
    padding: 5px 15px;
    padding-bottom: 200px;
    // overflow: scroll;
    // -webkit-overflow-scrolling: touch;
    // height: 100vh;
    // overflow: hidden;
    // overflow-y: auto;
    // max-height: 600px;
    // max-height: 250px;
    // border: 1px solid rgb(216, 216, 216);
    position: relative;
    // box-shadow: 3px 3px 3px -3px rgb(174, 174, 174),-3px 3px 3px -3px rgb(174, 174, 174);;
  }
  .item-box .list{
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    width: 100%;
    margin: 0px;
    padding: 10px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    padding-bottom: 0px;
  }
  .item-box .list .item {
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    margin-top: 7px;
    display: flex;
    width: 95%;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 75px;
    padding: 5px;
  }
  input[type='checkbox'] {
    cursor: pointer;
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    font-size: 0.9rem;
    visibility: hidden;
  }
  input[type='checkbox']::after {
    position: absolute;
    top: 0;
    left: 5px;
    // background-color: rgb(255, 255, 255);
    border: 1px solid #d3d3d3;
    color: #fff;
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    visibility: visible;
    padding-left: 0px;
    text-align: center;
    content: ' ';
    border-radius: 55%;
  }

  input[type='checkbox']:checked::after {
    content: '✔︎';
    position: absolute;
    color: var(--main-color);
    font-size: 0.9rem;
    font-weight: 900;
  }
  .item-box .list .item .middle .title {
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    width: 150px;
    display: flex;
    letter-spacing: 1px;
    justify-content: space-between;
  }
  .item-box .list .item .middle .entitle {
    margin-top: 5px;
    // transform: scale(0.8);
    margin-left: 1px;
    color: rgba(67, 67, 67, 0.851);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .item-box .list .item .middle .money {
    // margin-top: 18px;
    // transform: scale(0.8);
    margin-left: 2px;
    color: #F37423;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 1px;
  }
  .item-box .list .item .right {
    background-clip: text;
    font-size: 17px;
    // width: 70px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color:#F37423;
    padding: 0px;
    transform: translateX(-20px);
  }
  .bottom_right_button {
    // background-clip: text;
    font-size: 15px;
    border-radius:50px;
    width:90px;
    height: 35px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: rgb(255, 255, 255);
    background-color: #F37423;
    padding: 0px;
  }
</style>
