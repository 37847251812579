<template>
  <div class="menu">
    <div class="dia0">
        <!-- <label>CardView</label> -->
        <!-- 這是我們要塞表單的地方 -->
        <!-- <div id="cardview-container"></div> -->
        <!-- 這是我們要觸發 GetPrime 方法的地方 -->
      <div class="form-container">
        <div class="field-container">
            <label for="name">持卡人姓名</label>
            <input id="name" maxlength="20" type="text" v-model="cardholder.name">
        </div>
        <div class="field-container">
            <label for="PhoneNumber">電話</label>
            <input id="PhoneNumber" maxlength="20" type="text" v-model="cardholder.phone_number">
        </div>
        <div class="field-container">
            <label for="email">電子郵件</label>
            <input id="email" maxlength="50" type="text" v-model="cardholder.email">
        </div>
        <!-- <div class="field-container">
            <label for="bankId">銀行代碼</label>
            <input id="bankId" type="text" pattern="[0-9]*" inputmode="numeric">
        </div> -->
        <!-- <div class="field-container">
            <label for="cardnumber">卡片號碼</label>
            <input id="cardnumber" type="text" pattern="[0-9]*" inputmode="numeric" ref="number">
        </div>
        <div class="field-container">
            <label for="expirationdate">到期日(mm/yy)</label>
            <input id="expirationdate" type="text" pattern="[0-9]*" inputmode="numeric" ref="expirationDate">
        </div>
        <div class="field-container">
            <label for="securitycode">安全碼(ccv)</label>
            <input id="securitycode" type="text" pattern="[0-9]*" inputmode="numeric" ref="ccv">
        </div> -->
        <br>
        <div class="field-container">
          <div id="cardview-container"></div>
        </div>
      </div>
      <br>
      <cube-button style="width: 70%; margin: 0 auto; background-color: var(--main-color)" @click="onSubmit()">送出</cube-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
// eilis
// const APP_ID = '13409'
// const APP_KEY = 'app_okF2foaTRN9l2LxPWs03FxMbHJB8CF8oNDhrF5JXTHefqyegooEKRAaufdwv'

const APP_ID = '121466'
const APP_KEY = 'app_av0qlQMRfSby5hjbbcSuXYO5NAMGIB9QsuFCvLqhQfBpp9R39kEVsjGGenPE'
export default {
  data () {
    return {
      disabledBtnPay: false,
      creditCard: false,
      expirationDate: '',
      ccv: '',
      cardholder: {
        name: '',
        phone_number: '',
        email: '',
      },
    }
  },
  mounted() {
    console.log(this.creditContent);
    console.log(this.personalData);
    this.cardholder.phone_number = this.personalData.phone
    TPDirect.setupSDK(APP_ID, APP_KEY, 'production'); // DEV NOTE: 20210914 Mark change 'sandbox' to 'production'
    // 把 TapPay 內建輸入卡號的表單給植入到 div 中
    TPDirect.card.setup('#cardview-container');
  //   const fields = {
  //     number: {
  //       element: this.$refs.number,
  //       placeholder: '**** **** **** ****',
  //     },
  //     expirationDate: {
  //       element: this.$refs.expirationDate,
  //       placeholder: 'MM/YY',
  //     },
  //     ccv: {
  //       element: this.$refs.ccv,
  //       placeholder: '後三碼',
  //     },
  //   };
  //   TPDirect.card.setup({
  //    fields: fields,
  //    styles: {
  //      // Style all elements
  //      input: {
  //        color: 'gray',
  //      },
  //      // Styling ccv field
  //      'input.cvc': {
  //        'font-size': '16px',
  //      },
  //      // Styling expiration-date field
  //      'input.expiration-date': {
  //        // 'font-size': '16px'
  //      },
  //      // Styling card-number field
  //      'input.card-number': {
  //        // 'font-size': '16px'
  //      },
  //      // style focus state
  //      ':focus': {
  //        // 'color': 'black'
  //      },
  //      // style valid state
  //      '.valid': {
  //        color: 'green',
  //      },
  //      // style invalid state
  //      '.invalid': {
  //        color: 'red',
  //      },
  //      // Media queries
  //      // Note that these apply to the iframe, not the root window.
  //      '@media screen and (max-width: 400px)': {
  //        input: {
  //          color: 'orange',
  //        },
  //      },
  //    },
  //  });

   TPDirect.card.onUpdate(update => {
     console.log(update);
     if (update.canGetPrime) {
       //全部欄位皆為正確 可以呼叫 getPrime
       this.disabledBtnPay = false;
     } else {
       this.disabledBtnPay = true;
     }

     this.updateStatus(update.status.number);
     this.updateStatus(update.status.expiry);
     this.updateStatus(update.status.number);
   });
  },
  props: {
    creditContent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      personalData: 'personalData',
      brandData: 'brandData',
      shopCart: 'shopCart',
      imCart: 'imCart',
      Shop: 'Shop',
    }),
    shopCar: {
      get () { return this.shopCart },
      set (value) { this.updateShopCart({ shopCart: value }); },
    },
    shopContent: {
      get () { return this.Shop },
      set (value) { this.updateShopName({ ShopName: value }); },
    },

  },
  methods: {
  ...mapActions({
    azureSendOnlineOrder: 'azureSendOnlineOrder',
    getAzureToken: 'getAzureToken',
  }),
  ...mapMutations({
    updateShopCart: 'updateShopCart',
    updateShopName: 'updateShopName'
  }),
    updateStatus(field) {
      switch (field) {
        case 0:
          //欄位已填好，並且沒有問題
          console.log('field is ok');
          break;
        case 1:
          //欄位還沒有填寫
          console.log('field is empty');
          break;
        case 2:
          //欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
          console.log('field has error');
          break;
        case 3:
          //使用者正在輸入中
          console.log('usertyping');
          break;
        default:
          console.log('error!');
      }
    },
    onSubmit() {
      // this.$emit('gotoP', '首頁', '購物車')
      // this.creditContent.orderId = '11';
      // console.log(this.creditContent);
      // return;
      this.$loading.show()
      // if (!this.cardholder.name || !this.cardholder.phone_number || !this.cardholder.email) {
      if (!this.cardholder.name || !this.cardholder.phone_number) {
        this.$createToast({
          type: 'error',
          txt: '個人資訊有誤，請重新輸入',
          time: 1500
        }).show()
        this.$loading.hide()
        return;
      }
      if (this.disabledBtnPay === true) {
          this.$createToast({
          type: 'error',
          txt: '信用卡資訊有誤，請重新輸入',
          time: 1500
        }).show()
        this.$loading.hide()
        return;
      } else {

      // Get prime
      TPDirect.card.getPrime(result => {
        if (result.status !== 0) {
          // get prime error
          console.log(result.msg);
          this.$createToast({
          type: 'error',
          txt: '資料請輸入正確',
          time: 1500
        }).show()
        this.$loading.hide()
        return;
        }
        console.log(result);
        let primeContent = result.card;

        this.submitPrime(primeContent);
      });
      }
    },
    async submitPrime(primeContent) {
      try {
        // 要把得到的Prime Token 送給後端,
        this.azureSendOnlineOrder({
          accountId: this.creditContent.accountId,
          orderId: this.creditContent.orderId,
          posId: this.creditContent.posId,
          userId: this.creditContent.userId,
          name: this.creditContent.name,
          phone: this.creditContent.phone,
          payWay: this.creditContent.payWay,
          price: this.creditContent.price,
          totalPrice: this.creditContent.totalPrice,
          itemList: this.creditContent.itemList,
          bookTime: this.creditContent.bookTime,
          ban: this.creditContent.ban, // 統編
          remark: this.creditContent.reamrk, // 備註
          bankId: 'bank',
          prime: primeContent.prime,
          callbackUrl: `https://liff.line.me/${this.eilisProfile.liffId}`,
          cardholder: {
            phone_number: this.cardholder.phone_number,
            name: this.cardholder.name,
            email: this.cardholder.email,
          },
        })
        .then((res) => {
          if (res.data.status === 10020) {
            location.href = res.data.paymentUrl
            // this.$createToast({
            //   type: 'correct',
            //   txt: '訂單已送出',
            //   time: 1000
            // }).show()
            // this.$emit('gotoP', '首頁', '購物車')
            // this.shopCar = [];
            this.$loading.hide();
          } else {
            const toast = this.$createToast({
              type: 'correct',
              txt: '請重新操作',
              time: 1000
            })
            this.getAzureToken({
              poiId: this.Shop.poiId,
              secretkey: this.Shop.secretkey,
            })
            .then((res) => {
              // console.log('');
              if (res.data && res.data.shopId) {
                toast.show();
                this.shopContent.orderToken = res.data.orderToken;
                this.creditContent.orderId = res.data.orderToken;
                this.$loading.hide();
              } else {
                this.$loading.hide();
              }
            })
          }
        })
      } catch (err) {
        console.log(err);
      }
    },
    firstGate() {
      if (!this.selectedTime) {
        const toast = this.$createToast({
          type: 'correct',
          txt: '請選擇取貨時間',
          time: 1000
        }).show()
        return toast.show();
      }
      this.$createDialog({
        type: 'alert',
        title: '系統通知',
        content: '確認後送出',
        showClose: true,
        // icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
            this.sendAuzreOrder()
        },
      }).show()
    },
    beforeSendOrder() {
      if (this.checkList.length < 1) {
        this.$createDialog({
          type: 'confirm',
          title: '系統通知',
          content: '還未點選所需品項',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '取消',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
        }).show()
      } else if (this.selectedTime === '') {
        this.$createToast({
          type: 'correct',
          txt: '取貨時間必填',
          time: 1000
        }).show()
      }
      else {
        this.$createDialog({
          type: 'confirm',
          title: '系統通知',
          content: '確認訂單送出嗎？',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '取消',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
          onConfirm: () => {
            this.sendOrder()
          },
        }).show()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  body {
  font: 300 14px/18px Roboto;
  // overflow-x:hidden;
  // overflow-y:hidden;
  // overflow: hidden;
  // height:100%;
}

*,
:after,
:before {
  box-sizing: border-box
}
  .dia0 {
    z-index: 100000000000;
    padding: 30px;
    width: 90vw;
    background-color: rgba(255, 255, 255, 0.166);
    max-width: 500px;
    height: 550px;
    border-radius: 10px;
    border: 1px solid var(--main-color);
    margin: 50px auto;
  }
  .menu {
    z-index: 10000000000000;
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    width: 100%;
    height: 1000px;
    max-width: 540px;
    background-color: #f9f9f927;
    // overflow: hidden;
    // -webkit-overflow-scrolling: touch;
    // overflow-y: auto;
    // -ms-overflow-y: auto;
    // overflow: scroll;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .box {
    z-index: 10;
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 320px;
    // overflow: scroll;
    // -webkit-overflow-scrolling: touch;
    // overflow-y: auto;
    // -ms-overflow-y: auto;
  }
  .form-container .field-container:first-of-type {
    grid-area: name;
  }

  .form-container .field-container:nth-of-type(2) {
    grid-area: PhoneNumber;
  }
  .form-container .field-container:nth-of-type(3) {
    grid-area: email;
  }
  .form-container .field-container:nth-of-type(4) {
    grid-area: bankId;
  }
  .form-container .field-container:nth-of-type(5) {
    grid-area: number;
  }

  .form-container .field-container:nth-of-type(6) {
    grid-area: expiration;
  }

  .form-container .field-container:nth-of-type(7) {
    grid-area: security;
  }

  .field-container input {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
  }

  .field-container {
      position: relative;
      text-align: left;
  }

  .form-container {
      display: grid;
      grid-column-gap: 10px;
      grid-template-columns: auto auto;
      grid-template-rows: 90px 90px 90px;
      grid-template-areas: "name name""PhoneNumber PhoneNumber""email email""bankId bankId""number number""expiration security";
      max-width: 400px;
      padding: 20px;
      color: #707070;
  }

  label {
      padding-bottom: 5px;
      font-size: 13px;
  }

  input {
      margin-top: 3px;
      padding: 15px;
      font-size: 16px;
      width: 100%;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
  }

  .ccicon {
      height: 38px;
      position: absolute;
      right: 6px;
      top: calc(50% - 17px);
      width: 60px;
  }

  /* CREDIT CARD IMAGE STYLING */
  .preload * {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -ms-transition: none !important;
      -o-transition: none !important;
  }

  .container {
      width: 100%;
      max-width: 400px;
      max-height: 251px;
      height: 54vw;
      padding: 20px;
  }

  #ccsingle {
      position: absolute;
      right: 15px;
      top: 20px;
  }

  #ccsingle svg {
      width: 100px;
      max-height: 60px;
  }
</style>